//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    testimonial: {
      type: Object,
      required: true
    },
    testimonialStyle: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: true
    }
  },
  computed: {
    imgWrapperTag () {
      return this.testimonial.url.cached_url ? 'a' : 'span'
    },
    imgWrapperProps () {
      if (!this.testimonial.url.cached_url) {
        return {}
      }
      return {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: this.testimonial.url.cached_url,
      }
    },
    logo () {
      return this.$sb.parseImage(this.testimonial.logo)
    },
    isSvg () {
      return this.logo?.extension === 'svg'
    },
    textClass () {
      const cls = []

      switch (this.testimonialStyle) {
        case 'white':
          cls.push('tw-text-gray-700')
          break
        case 'black':
          cls.push('tw-text-gray-200')
          break
      }

      switch (this.size) {
        case 'sm':
          cls.push('tw-text-sm', 'tw-leading-6')
          break
        case 'md':
          cls.push('tw-text-lg', 'sm:tw-text-xl', 'tw-leading-8')
          break
        case 'lg':
          break
      }

      return cls
    },
    authorClass () {
      switch (this.testimonialStyle) {
        case 'white':
          return 'tw-text-gray-900'
        case 'black':
          return 'tw-text-white'
        default:
          return ''
      }
    }
  }
}
