//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import blok from '~/mixins/blok'
export default {
  mixins: [blok],
  computed: {
    size () {
      switch (this.blok.testimonials.length) {
        case 1:
          return 'lg'
        case 2:
          return 'md'
        case 3:
        default:
          return 'sm'
      }
    },
    containerClass () {
      switch (this.blok.style) {
        case 'white':
          return 'tw-bg-white'
        case 'cream':
          return 'tw-bg-cream-50'
        case 'black':
          return 'tw-bg-gray-800'
        default:
          return ''
      }
    },
    gridClass () {
      const cls = []

      switch (this.size) {
        case 'lg':
          cls.push('tw-grid-cols-1')
          break
        case 'md':
          cls.push('tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-16 tw-divide-y md:tw-divide-y-0')
          break
        case 'sm':
        default:
          cls.push('tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-12 tw-divide-y md:tw-divide-y-0')
          break
      }

      switch (this.blok.style) {
        case 'white':
          cls.push('tw-divide-gray-200')
          break
        case 'cream':
          cls.push('tw-divide-latte-300')
          break
        case 'black':
          cls.push('tw-divide-gray-600')
          break
      }

      return cls
    },
  }
}
